<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">余额设置</div>
			
			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<div class="">
						<div class="ft14 ftw500 cl-info">积分抵扣余额上限比</div>
						<div class="mt16">
							<div class="flex alcenter">
								<div style="position: relative;">
									<a-input-number v-model="form.integral_deduction_balance_limit_rate" style="width: 160px;" :precision="2" :min="0" placeholder="请输入比例"/>
									<div class="setting-balance-addon">%</div>
								</div>
								
								<div class="ft14 ftw500 cl-info ml16">如比例是50%，买单金额为100元，那么会员最多可用积分抵扣50元</div>
							</div>
						</div>
					</div>
					
					<!-- <div class="mt24">
						<div class="ft14 ftw500 cl-info">余额消耗返现金设置</div>
						<div class="mt16">
							<div class="setting-balance-sku">
								<div style="width: 50%;" class="flex space ft14 ftw500 cl-main pr10 pl20">
									<div>消耗金额</div>
								</div>
								
								<div class="pl10">返还现金</div>
							</div>
							
							<div class="setting-balance-sku-item" v-for="(item,index) in form.consume">
								<div style="position: relative;">
									<a-input-number style="width: 320px;" v-model="item.out_price" :precision="0" :min="0" />
									<div class="setting-balance-sku-item-addon">元</div>
								</div>
								
								<div style="position: relative;margin-left: 20px;">
									<a-input-number style="width:320px;"  v-model="item.in_price" :precision="2" :min="0" />
									<div class="setting-balance-sku-item-addon">元</div>
								</div>
								
								<i class="iconfont iconbtn_close ft16 clickAct ml20" @click="delConsumeItem(index)"/>
							</div>
							
							<div class="flex end mt10" style="width: 750px;">
								<a-button icon="plus" type="primary" ghost @click="addConsumeItem()">新建</a-button>
							</div>
						</div>
					</div> -->
					
					<div class="mt24">
						<div class="ft14 ftw500 cl-info">余额充值套餐设置</div>
						<div class="mt16">
							<div class="setting-balance-sku">
								<div style="width: 33%;" class="flex space ft14 ftw500 cl-main pr10 pl20">
									<div>充值金额</div>
								</div>
								
								<div class="pl10 flex alcenter" style="width: 33%;">
									<div>赠送金额</div>
									<div class="ft12 ftw500 cl-notice">(填0为不赠送)</div>
								</div>
								
								<div class="pl10 flex alcenter" style="width: 34%;">
									<div>赠送优惠券</div>
									<div class="ft12 ftw500 cl-notice">(可不选)</div>
								</div>
							</div>
							
							<div class="setting-balance-sku-item" v-for="(item,index) in form.taocan_detail">
								<div style="position: relative;" v-if="index==0">
									<a-input-number style="width: 160px;" v-model="item.recharge_price" :precision="2" :min="0" />
									<div class="setting-balance-sku-item-addon">元</div>
								</div>
								
								<div style="position: relative;" v-if="index!=0">
									<a-input-number style="width: 160px;" v-model="item.recharge_price" :precision="2" :min="form.taocan_detail[index-1].recharge_price" />
									<div class="setting-balance-sku-item-addon">元</div>
								</div>
								
								<div style="position: relative;margin-left: 20px;">
									<a-input-number style="width:160px;"  v-model="item.give_price" :precision="2" :min="0" />
									<div class="setting-balance-sku-item-addon">元</div>
								</div>
								
								<div class="ml20">
									<div class="setting-balance-sku-item-coupon">
										<div class="flex space alcenter" style="width: 100%;height: 100%;">
											<div class="ft12 ftw500 cl-notice">已选：{{item.coupon.length}}</div>
											<div class="ft12 ftw500 cl-theme clickAct" @click="chooseCouponAct(index)">去选择</div>
										</div>
									</div>
								</div>
								
								<i class="iconfont iconbtn_close ft16 clickAct ml20" @click="delRechargeItem(index)"/>
							</div>
							
							<div class="flex end mt10" style="width: 636px;">
								<a-button icon="plus" type="primary" ghost @click="addRechargeItem()">新建</a-button>
							</div>
						</div>
					</div>
					
					<div class="mt24">
						<div class="ft14 ftw500 cl-info">是否冻结赠送金额</div>
						<div class="mt16">
							<a-radio-group v-model="form.is_frozen_give_money">
								<a-radio :value="1">
									是
								</a-radio>
								<a-radio :value="0">
									否
								</a-radio>
							</a-radio-group>
						</div>
						
						<div class="mt16" v-if="form.is_frozen_give_money==1" >
							<div style="position: relative;width:320px;">
								<a-input-number v-model="form.frozen_days" style="width:320px;" :precision="0" :min="0" placeholder="请输入冻结天数"/>
								<div class="setting-balance-addon">天</div>
							</div>
						</div>
					</div>
					
					<div class="mt30">
						<a-button type="primary" :loading="confirmLoading" @click="save()">保存</a-button>
					</div>
				</div>
			</div>
				
			<div v-if="chooseCouponLoading">
				<choose-coupon v-model="form.taocan_detail[index].coupon" :visible="chooseCouponLoading" @cancel="cancelChooseCoupon" @ok="okChooseCoupon"></choose-coupon>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import chooseCoupon from './components/balance/modal/chooseCoupon.vue';
	export default{
		components:{
			chooseCoupon
		},
		data(){
			return{
				loading:false,
				confirmLoading:false,
				chooseCouponLoading:false,
				index:0,
				form:{
					integral_deduction_balance_limit_rate:'',
					taocan_detail:[],
					is_frozen_give_money:0,
					frozen_days:0,
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showBalanceSetting').then(res=>{
					this.form=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			save(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveBalanceSetting',{
					integral_deduction_balance_limit_rate:this.form.integral_deduction_balance_limit_rate,
					taocan_detail:JSON.stringify(this.form.taocan_detail),
					is_frozen_give_money:this.form.is_frozen_give_money,
					frozen_days:this.form.frozen_days,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			
			// addConsumeItem() {
			// 	this.form.consume.push({
			// 		out_price: '',
			// 		in_price: ''
			// 	});
			// },
			// delConsumeItem(index) {
			// 	this.form.consume.splice(index, 1);
			// },
			
			addRechargeItem() {
				this.form.taocan_detail.push({
					recharge_price: '',
					give_price: '',
					coupon:[],
				});
			},
			delRechargeItem(index) {
				this.form.taocan_detail.splice(index, 1);
			},
			
			chooseCouponAct(index){
				this.index=index;
				this.chooseCouponLoading=true;
			},
			cancelChooseCoupon(){
				this.chooseCouponLoading=false;
			},
			okChooseCoupon(){
				this.chooseCouponLoading=false;
			},
		}
	}
</script>

<style>
	.setting-balance-addon{
		position: absolute;
		top:0;
		right:26px;
		height: 32px;
		line-height: 32px;
		
		font-size: 14px;
		font-weight: 500;
		color: #A6AEC2;
	}
	
	.setting-balance-sku {
		width: 636px;
		height: 40px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		display: flex;
		line-height: 40px;
	}
	
	
	.setting-balance-sku .ant-select-arrow {
		color: #656A75;
	}
	
	.setting-balance-sku .ant-select-selection {
		background-color: #F9F9F9;
		border:none;
		box-shadow: none;
	}
	
	.setting-balance-sku-item{
		width: 636px;
		display: flex;
		border-bottom: 1px solid #EBEDF5;
		padding: 10px 20px 10px 20px;
	}
	
	.setting-balance-sku-item-addon{
		position: absolute;
		height: 32px;
		line-height: 32px;
		right: 26px;
		top:0px
	}
	
	.setting-balance-sku-item-coupon{
		width: 200px;
		height: 32px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #EBEDF5;
		padding: 0px 16px 0px 16px;
	}
</style>
